const isProd = true;
const isEU = true;

const prodConfig = {
  url: "https://api.thequack.ai/",
  cloudfront: "https://sdk-quack.s3.amazonaws.com/p/",
  aiUrl: "https://ai.quack.cx",
  env: "prod",
  s3Config:
    "https://quack-production-sdk-configurations.s3.us-east-1.amazonaws.com/",
};

const euProdConfig = {
  url: "https://eu.api.thequack.ai/",
  aiUrl: "https://ai-eu.quack.cx",
  cloudfront: "https://sdk-quack.s3.amazonaws.com/eu/",
  env: "eu",
  s3Config:
    "https://quack-eu-sdk-configurations.s3.eu-central-1.amazonaws.com/",
};

const stgConfig = {
  url: "https://stage-api.thequack.ai/",
  cloudfront: "http://sdk-quack.s3.amazonaws.com/s/",
  aiUrl: "https://ai.quack.cx",
  env: "stg",
  s3Config: "https://quack-stg-sdk-configurations.s3.us-east-1.amazonaws.com/",
};

export const {
  url: API_URL,
  aiUrl: AI_URL,
  cloudfront: CLOUDFRONT_URL,
  env: ENV,
  s3Config: S3_CONFIG,
} = isProd ? (isEU ? euProdConfig : prodConfig) : stgConfig;
