import React from "react";
import { Badge, Flex, IconButton } from "@radix-ui/themes";
import { ConnectionStatus } from "./api/useConnectChatSession";
import { ReloadIcon } from "@radix-ui/react-icons";
import { clearChatSessionResponseFromLocalStorage } from "./api/useChatSession";
interface ConnectionStatusProps {
  tenant: string;
  status: ConnectionStatus;
  startNewChat: () => Promise<void>;
}

export function ConnectionStatus({
  tenant,
  status,
  startNewChat,
}: ConnectionStatusProps) {
  return (
    <div className="qc-Chat__Connection_Status">
      <Flex align={"center"} gap={"2"}>
        <Badge
          size={"2"}
          color={
            status === "disconnected"
              ? "red"
              : status === "ready"
              ? "green"
              : "gray"
          }
        >
          {status}
        </Badge>

        <IconButton
          size={"2"}
          color="gray"
          variant="ghost"
          onClick={() => {
            clearChatSessionResponseFromLocalStorage(tenant);
            void startNewChat();
          }}
        >
          <ReloadIcon width="15" height="15" />
        </IconButton>
      </Flex>
    </div>
  );
}

export function TestingMode({
  tenant,
  startNewChat,
}: Pick<ConnectionStatusProps, "startNewChat" | "tenant">) {
  return (
    <div className="qc-Chat__Connection_Test">
      <Flex align={"center"} gap={"2"}>
        <IconButton
          size={"2"}
          color="gray"
          variant="ghost"
          onClick={() => {
            clearChatSessionResponseFromLocalStorage(tenant);
            void startNewChat();
          }}
        >
          <ReloadIcon width="15" height="15" />
        </IconButton>
      </Flex>
    </div>
  );
}
